import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import headerStyles from './header.module.scss'
import logo from '../images/logo-site-cerruns.png'
import hamburger from '../images/hamburger-icon.png'

const NAV_CONTAINER_CLASS = 'navContainer'
const HIDE_CLASS = 'hide'

class Header extends React.Component {
  constructor(props) {
    super(props)
    // this.handleClickBurgerMenu = this.handleClickBurgerMenu.bind(this)
    this.state = {
      classNameNav: `${headerStyles[NAV_CONTAINER_CLASS]} ${headerStyles[HIDE_CLASS]}`
    }
  }

  // headerStyles.navContainer
  handleClickBurgerMenu = () => {
    const { classNameNav } = this.state

    if (classNameNav === headerStyles[NAV_CONTAINER_CLASS]) {
      this.setState({
        // classNameNav: headerStyles[HIDE_CLASS]
        classNameNav: `${classNameNav} ${headerStyles[HIDE_CLASS]}`
      })
    } else {
      this.setState({
        classNameNav: headerStyles[NAV_CONTAINER_CLASS]
      })
    }
    // console.log('classNameNav headerStyles', headerStyles[this.state.classNameNav]);
  }

  render() {
    const { classNameNav } = this.state

    return (
      <header className={headerStyles.header}>
        <div className={headerStyles.divChangeFlexBox}>
          <div className={headerStyles.divAlwaysFlex}>
            <Link className={headerStyles.title} to="/">
              <img className={headerStyles.logo} src={logo} alt="logo"></img>
            </Link>
            <img className={headerStyles.hamburgerMenu}
              src={hamburger}
              alt="hamburger menu"
              onClick={this.handleClickBurgerMenu}>
            </img>
          </div>
          <nav className={classNameNav}>
            <ul className={headerStyles.navList}>
              <li>
                <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/">Home</Link>
              </li>
              <li>
                <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/produtos">Produtos</Link>
              </li>
              <li>
                <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/servicos">Serviços</Link>
              </li>
              <li>
                <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/lojas">Onde encontrar</Link>
              </li>
              <li>
                <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/contato">Contato</Link>
              </li>
              <li>
                <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/sobre">Sobre</Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    )
  }
}

// <li>
//   <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/blog">Blog</Link>
// </li>

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
