import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import footerStyles from './footer.module.scss'

const Footer = ({ siteAuthor }) => {
  const data = useStaticQuery(graphql`
    query {
      facebook: file(relativePath: { eq: "f-logo-RGB-Grey-58.png" }) {
        childImageSharp {
          fixed(width: 28) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <footer className={footerStyles.footer}>
      <div className={footerStyles.footerContent}>
        <p className={footerStyles.copyright}>{siteAuthor} © {new Date().getFullYear()}</p>
        <OutboundLink href="https://www.facebook.com/cerruns" target="_blank" rel="noopener noreferrer">
          <Img fixed={data.facebook.childImageSharp.fixed}
            fadeIn={false}
            title="Nossa página no Facebook"
            alt="Logo do Facebook"
          />
        </OutboundLink>
        <p className={footerStyles.endereco}>Alameda Conde de Porto Alegre, 1100<br />
          Bairro Olímpico - São Caetano do Sul - SP<br />
          CEP 09561-000<br />
          Cel: (11) 96102-5562<br />
          WhatsApp: (11) 98815-0655<br />
          Tel: (11) 2311-3395 / 2311-3413<br />
          Email: <a href="mailto:contato@cerruns.com.br">contato@cerruns.com.br</a>
        </p>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteAuthor: PropTypes.string
}

Footer.defaultProps = {
  siteAuthor: ''
}

export default Footer