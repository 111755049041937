/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from './footer'
import "../styles/base.scss"
import layoutStyles from './layout.module.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            author
          }
        }
      }
    `}
    render={data => (
        <div className={layoutStyles.container}>
          <div className={layoutStyles.content}>
            <Header siteTitle={data.site.siteMetadata.title} />
            <main>{children}</main>
          </div>
          <Footer siteAuthor={data.site.siteMetadata.author}/>
        </div>
    )}
  />
)

// style={{
//   margin: `0 auto`,
//   maxWidth: 960,
//   padding: `0px 1.0875rem 1.45rem`,
//   paddingTop: 0,
// }}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
